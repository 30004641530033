body div#menu- div.MuiMenu-paper {
    width: 600px;
    min-width: 300px !important;
}

body div#menu- div.MuiMenu-paper ul[role="listbox"] {
    display: flex;
    padding-right: 0;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}